import React from 'react';
import './generalInsurance.css'

const GeneralInsurance = () => {
    return (
        <div className='GeneralInsurance'>
            <div className="GeneralInsuranceContainer">
                <div className="GeneralInsuranceImgContainer">
                    <img src="Images/GeneralInsurance.jpg" alt="Img" className="GeneralInsuranceImg" />
                    <h1 className='text-4xl lg:text-[32pt] lg:leading-[64pt] font-normal ml-20'>General Insurance<hr /></h1>
                </div>
                <div className="flex items-center px-2 py-4 LifeInsuranceContainer1">
                    <div className="LifeInsuranceContainer2">
                        <h4 className='font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] LIH4'><b>General Insurance Overview</b><hr /></h4>
                        <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>General insurance shields certain aspects that are valuable to you. It involves vehicles, valuables and homes subjected to any risks.</p>
                        <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>General insurance safeguards things from flood, earthquake, fire, storm, theft, accidents or any other mishaps.</p>
                        <h5 style={{ color: '#3b82f6', fontWeight:"bold" }}>Concept of General Insurance</h5>
                        <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>The concept behind general insurance is to help individuals in bearing all their financial losses on an asset. Any risk imposed by diseases, natural calamities, medical emergencies and accidents can be protected under the umbrella of general insurance.</p>
                    </div>
                    <div className="GIImg">
                        <img src="Images/TypesOfInsurancePolicy.jpg" alt="Img" className="LIImg2" />
                    </div>
                </div>
                <div className="px-8 py-4 GIC">
                    <h4 className='font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] GIH4'><b>General Insurance Plans</b><hr /></h4>
                    <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>A general insurance secures your valuable possessions from any unforeseeable mishaps. Be it your home, vehicle or jewellery or any other assets, a general insurance ensures that they are always safe and protected from any natural disasters, theft or accidents. Depending upon the asset you need insured, there are several types of general insurance available in the market provided by both banks, NBFCs and independent insurance companies.</p>
                </div>
                <div className="px-8 py-4 GIC">
                    <h4 className='font-Medium lg:text-[22pt] lg:leading-[28pt]  text-[#1f2360] md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] GIH4'><b>Types of General Insurance Policies</b><hr /></h4>
                    <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>The best general insurance products are as follows:</p>
                    <h5>Motor Insurance</h5>
                    <p className=' GITypes font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt] >'>Motor insurance is available for two-wheelers, cars, and commercial vehicles. The advantages are cashless garages, roadside assistance and personal accident cover. Motor insurance includes a third-party insurance policy and a comprehensive insurance policy.</p>
                    <h5>Health Insurance</h5>
                    <p className=' GITypes font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt] >'>Health insurance covers the cost of healthcare and surGIC px-8 py-4al costs for you. The health insurance includes cashless facilities, pre and post hospitalization costs, ambulance charges, everyday cash and so on.</p>
                    <h5>Travel Insurance</h5>
                    <p className=' GITypes font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt] >'>Travel insurance covers all medical and non-medical issues. It protects the insured person against all mishaps during travelling.</p>
                    <h5>Home Insurance</h5>
                    <p className=' GITypes font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt] >'>The home insurance guards the house and its contents. It all depends on the insurance plan you choose. Further, it ensures coverage against man-made disasters, natural calamities and other related threats.</p>
                    <h5>Mobile Insurance</h5>
                    <p className=' GITypes font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt] >'>This general insurance type is created to reimburse for mobile repairs. The mobile insurance is reasonable and easily available. The best part is you can buy mobile insurance for both new and old mobile phones.</p>
                    <h5>Rural Insurance</h5>
                    <p className=' GITypes font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt] >'>It helps in meeting the requirements of rural business and agriculture. Also, IRDA has pre-defined the annual targets for all the insurance companies to provide insurance to all the social and rural sectors.</p>
                    <h5>Marine Insurance</h5>
                    <p className=' GITypes font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt] >'>Shipping businesses do the import and export of goods, through national and international borders. But sometimes, accidents can damage the shipments and cause financial losses. Marine cargo insurance covers goods, freight and cargo against every loss during the transit.</p>
                    <h5>Commercial Insurance</h5>
                    <p className=' GITypes font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt] >'>This general insurance types include solutions for small, medium and large industries that may run out of business operations. It includes construction, aviation, automotive, foods and beverages, chemicals, oil and gas,  power, telecom, pharmaceuticals, technology, textiles, transport, logistics, and so on. </p>
                </div>
                <div className="px-8 py-4 GIC">
                    <h4 className='font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] GIH4'><b>Tax Benefits from General Insurance</b><hr /></h4>
                    <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Under general insurance, only health insurance plans offer tax benefits. According to Section 80D of the Income Tax Act 1961, the premium amount paid for the health insurance policy is eligible for tax deduction from your income.</p>
                    <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Here is the table representing the tax deduction on the Health General Insurance Plan in India.</p>
                    <table>
                        <tr><th className='Left-Heading'>Members</th>
                            <th className='Right-Heading'>Maximum Deduction</th></tr>
                        <tr><td className='Left-Cell'>Self, Spouse and Dependent Children</td>
                            <td className='Right-Cell'>Rs 25,000</td></tr>
                        <tr><td className='Left-Cell'>Self, spouse, dependent children Parents below 60 years of age</td>
                            <td className='Right-Cell'>up to Rs 50,000</td></tr>
                        <tr><td className='Left-Cell'>Self, spouse, dependent children Parents above 60 years of age</td>
                            <td className='Right-Cell'>up to Rs.75, 000</td></tr>
                        <tr><td className='Left-Cell'>Self (above 60 years of age) but paying for self, spouse, dependent children and dependent parents (above 60 years).</td>
                            <td className='Right-Cell'>Rs 1,00,000</td></tr>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default GeneralInsurance
