import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';


const ScrollTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      // Scrolls to the top when the pathname changes
      window.scrollTo(0, 0);
    }, [pathname]);
  
    // This component doesn't render anything; it's just for side effects
    return null;
}

export default ScrollTop
