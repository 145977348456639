import React from 'react'
import './lifeinsurance.css'

const LifeInsurance = () => {
  return (
    <>
      <div className='LifeInsurance'>
        <div className="LifeInsuranceContainer ">
          <div className="LifeInsuranceImgContainer ">
            <img src="Images/LifeInsurance.jpg" alt="Img" className="LifeInsuranceImg " />
            <h1 className='text-4xl lg:text-[32pt] lg:leading-[64pt] font-normal absolute ml-20'>Life Insurance<hr /></h1>
          </div>
          <div className="flex items-center px-2 py-4 LifeInsuranceContainer1">
            <div className="LifeInsuranceContainer2 ">
              <h4 className='LIH4 font-Medium lg:text-[22pt] lg:leading-[28pt]  text-[#1f2360] md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt]'><b>What is Life Insurance?</b><hr /></h4>
              <p className=' font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Life Insurance is an assurance of financial cover to a beneficiary upon the death of the insurance holder or after a maturity period. This arrangement is entered through a legally binding contract between an insurance policy seller or provider and an insurance policyholder. The recipient appoints a beneficiary who will receive an insured financial aid after the death of the insurance holder. The insured amount is compiled over time by the policyholder through recurring monthly premium payments.</p>
            </div>
            <div className="LIImg w-[150%]">
              <img src="Images/what-is-life-insurance.png" alt="Img" className="LIImg2" />
            </div>
          </div>
          <div className="px-8 py-4 LIC">
            <h1 className='LIC-Heading font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt]'><b>Life Insurance Plans</b></h1><hr />
            <div className="LICPara">
              <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>A life insurance is a formidable financial cushion necessary for all. While it offers security to a family with a single breadwinner, it also helps individuals secure their life, especially post retirement. This is because a life insurance can be claimed by the insured even after it reaches maturity. It, therefore, promises a financially secured future, irrespective of anyone’s marital status, job or business stability.</p>
            </div>
          </div>
        </div>
        <div className="px-8 py-4 BenifitsContainer LIC">
          <h3 className="  font-Medium lg:text-[22pt] lg:leading-[28pt]  text-[#1f2360] md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] LIC-Heading font-Medium "><b>Types of Life Insurance</b></h3><hr />
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Even though life insurance, in general, is a straightforward financial investment with assured returns over maturity or after the death of the insured, there are various kinds of life insurances offered to the residents of India. These variations have their own targeted customers who may benefit from them uniquely. One has to carefully consider these types before choosing a plan that best suits them. </p>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Here are the types of life insurances offered in India.</p>
          <ul className='px-8'>
            <li className='py-1 px-[0px]  list-disc '><b>Term Life Insurance</b>: Term life insurance is a fairly basic and common insurance plan where customers have the freedom to choose a term for their insurance, such as 10 years, 20 years, etc. This kind has a relatively cheaper premium due to its nature being a pure life cover. There are usually not any returns under normal conditions or expiration of the term period. The benefits are only provided in case of death or illness. The financial benefit is provided to the beneficiary of the insurance holder after his/her death during the term.</li>
            <li className='py-1 px-[0px]  list-disc '><b>Money-Back Policy</b>: With a money-back life insurance policy, customers are eligible to receive a sum of money at regular intervals along with the full payment at the expiration of the term or after the death of the insurance holder. Being an extremely rewarding policy, Money-Back Life Insurance Policy is one of the most expensive policies to purchase. It is worth considering given its returns are assured in the form of survival benefits, death benefits and term-expiration benefits.</li>
            <li className='py-1 px-[0px]  list-disc '><b>Unit Linked Insurance Plan (ULIP)</b>:A hybrid of life insurance and investing, Unit Linked Insurance Plan (ULIP) is a unique and popular life insurance plan to look out for. The special features of ULIP include allocation of funds towards the policyholders' life insurance and rest invested in the various Investment instruments such as mutual funds, equities, etc. There are benefits of ULIP for a policyholder in terms of cash-flow allocation in relation to market behavior. The percentage of funds allocated for investments is generally handled by an expert fund manager.</li>
            <li className='py-1 px-[0px]  list-disc '><b>Whole Life Insurance</b>: A whole life insurance policy offers a financial cover for the entire lifetime of the policyholder. It is only after the demise of the policyholder that the beneficiary receives the premium amount. There are several benefits available with whole life insurance, such as collateral benefits for availing loans, dividend paybacks at regular intervals, etc.</li>
            <li className='py-1 px-[0px]  list-disc '><b>Endowment Plan</b>: A two-way beneficial life insurance plan is just as much an insurance policy as it is an investment with returns. The endowment plan life insurance policyholder will have the benefit of receiving the full amount upon the expiration date of the policy along with the profits or bonus relative to the number of years the insured survived. Many see this as an investment opportunity that also provides a life cover.</li>
          </ul>
        </div>
        <div className="px-8 py-4 BenifitsContainer LIC">
          <h3 className="  font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] LIC-Heading font-Medium "><b>Life Insurance Buying Guide</b></h3><hr />
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Just like any other investment that will cost you recurring charges or payments, buying an insurance policy will also incur monthly/regular premium payments. The insurance policy that you will buy will assess and generate a payment routine based on your profile and history. This payment may or may not become a major recurring expense depending on your income. But it is highly recommended to thoroughly assess the policies you will be purchasing and the company you will be purchasing them from. </p>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>When considering a life insurance policy, review the following factors.</p>
          <ul className='px-8'>
            <li className='py-1 px-[0px]  list-disc '><b>Decide the policy type that's best for you</b>: There are a few varieties of policy types that insurance companies offer. Assess the types based on your needs to decide what kind of insurance to go for and compare their prices to grab the best deal.</li>
            <li className='py-1 px-[0px]  list-disc '><b>Company history matters</b>: You are leaving your fortune with a company in trust that they will repay that in the future. Companies always check your eligibility and creditworthiness. But that should not be a one-way deed. You should make sure the insurance company you are investing in maintains the highest of your standards.</li>
            <li className='py-1 px-[0px]  list-disc '><b>Ascertain your needs</b>:Make a review of your needs as the gross necessities will depend on the number of family members involved, health conditions, etc. Once you are aware of your goals the following steps will be relatively smoother.</li>
            <li className='py-1 px-[0px]  list-disc '><b>Scout top organisations that provide life insurance policies</b>: It is important to find an institution that is established and trusted. This will ensure the highest quality of service. Decide if you would like to buy a policy from government institutions, such as LIC or SBI.</li>
            <li className='py-1 px-[0px]  list-disc '><b>Decide the policy type that's best for you</b>: There are a few varieties of policy types that insurance companies offer. Assess the types based on your needs to decide what kind of insurance to go for and compare their prices to grab the best deal.</li>
          </ul>
        </div>
        <div className="px-8 py-4 BenifitsContainer LIC">
          <h3 className="  font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] LIC-Heading font-Medium "><b>Best Life Insurance Plans in India 2023</b></h3><hr />
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>There are various companies offering high-end insurance products that serve the public needs in diverse ways. These products are often modified with current prices and policies with respect to both market behaviour and industry competition. Here are some of the best-fared life insurance plans in India in 2023.</p>
          <ul className='px-8'>
            <li className='py-1 px-[0px]  list-disc '><b>Max Life Smart Secure Plus Plans</b><br />Max Life Smart Secure Plus Plans offer great financial protection along with repayment flexibility. The minimum age to enter into this policy is 18 and a maximum of 65 years. The premium can be paid till the age of 44-60 years. There will be an assurance of up to INR 20 Lakhs in case of a base death benefit.</li>
            <li className='py-1 px-[0px]  list-disc '><b>HDFC Click 2 Protect Plus</b><br /> HDFC Click 2 Protect Plus is another Term-Life-Insurance Policy that has fared well among the public. HDFC Life Insurance introduced this plan with the aim to provide an extensive life cover at a lower cost along with complete protection for the customer and his/her loved ones. The policy is modifiable in terms of cover options, such as life option, extra-life option, income option, income plus option, etc.</li>
            <li className='py-1 px-[0px]  list-disc '><b>LIC’s Tech Term Plan</b><br /> Life Insurance Corporation (LIC) introduced the plan in the year 2019 and assures the customers of a pure protection policy. This policy is a non-linked non-profit policy that aims to provide financial cover for the benefactor at the time of the insurance holder’s demise.</li>
          </ul>
        </div>
        <div className="px-8 py-4 BenifitsContainer LIC">
          <h3 className="  font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] LIC-Heading"><b>Benefits of a Life Insurance Policy</b></h3><hr />
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>With an insurance policy, you can actually leave out much of your finance-related stress to the insurance company. A policy will give you an opportunity to leave your family members with much-needed future financial security. With many types of life insurance policies, it is possible to find something that can also become an investment. Here are some of the major benefits of owning a life insurance policy.</p>
          <ul className='px-8'>
            <li className='py-1 px-[0px]  list-disc '><b>Profitable Return on Investments</b><br />When you purchase an insurance policy, you are setting aside a small part of your income over a regular period of time. This accumulated fund is returned to you at the end of the term of the policy. There are many insurance types that offer survival bonuses and returns on investment along with the principal.</li>
            <li className='py-1 px-[0px]  list-disc '><b>Financial Cover in case of Death</b><br /> Insurance can help your family and loved ones with financial cover during an untimely death. This can take the pressure off the family in various ways. Insurances help cover funerals, deal with outstanding debts, etc.</li>
            <li className='py-1 px-[0px]  list-disc '><b>Collateral</b><br /> A life insurance policy can be considered as collateral for availing of a bank loan. The loan can be issued against the policy amount. It is to be noted that the loan amount will depend on the premium amount as only a certain percentage of it will be assured. This can be extremely handy in troubled times.</li>
            <li className='py-1 px-[0px]  list-disc '><b>Tax Exemptions</b><br />Insurance policies can help you claim your tax returns as most of the policy premiums are tax exempted. The Income Tax Act has given tax benefits on several types of investments for salaried employees.</li>
          </ul>
        </div>
        <div className="px-8 py-4 BenifitsContainer LIC">
          <h3 className="  font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] LIC-Heading"><b>How to Claim Life Insurance?</b></h3><hr />
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>When an insurance holder dies or the insurance policy reaches maturity, it is advised to reach out to the insurance company as soon as possible. In case of death, the benefactor or any relative can claim the insurance money. However, in the case of the expiration of the term, it is the company that usually reaches out first. The company can send information regarding the same a couple of months in advance. </p>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>To make an insurance money claim in case of a death, the IRDAI (Insurance Regulatory and Development Authority of India) has made the following suggestions:</p>
          <ul className='px-8'>
            <li className='py-1 px-[0px]  list-disc '>Inform the company as soon as possible.</li>
            <li className='py-1 px-[0px]  list-disc '>Take assistance from the insurance company agent.</li>
            <li className='py-1 px-[0px]  list-disc '>Provide the company with documents, such as a death certificate, policy letter, proof of relation, deeds of assignments and a form with the information for discharge execution.</li>
          </ul>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>It is possible that the company might ask for medical certificates of the deceased for inquiry.</p>
        </div>
        <div className="px-8 py-4 BenifitsContainer LIC">
          <h3 className="  font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] LIC-Heading"><b>Documents Requirements for Buying a Life Insurance Policy</b></h3><hr />
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Insurance policies are issued after the submission of complete valid documents and a post-background-check process. Identification and residential proofs are two of the mandated documents asked for verification. The complete list of documents required for buying a life insurance policy are as follows:</p>
          <div className="DockCardContainer">
          <div className="DockCard">Application form</div>
          <div className="DockCard">Complete medical checkup report</div>
          <div className="DockCard">Photograph of the applicant</div>
          <div className="DockCard">Bank statement</div>
          <div className="DockCard">Income proof</div>
          <div className="DockCard">Age proof</div>
          <div className="DockCard">PAN details</div>
          <div className="DockCard">Residence proof</div>
          <div className="DockCard">Identification proof</div>
          </div>
        </div>
        <div className="px-8 py-4 BenifitsContainer LIC">
          <h3 className="  font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] LIC-Heading"><b>How to Save Tax with a Life Insurance Policy?</b></h3><hr />
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Investing in life insurance can prove to be a major tax saver as according to Section 80 C of the Income Tax Act 1961, you can reclaim your tax deductions for the payments made against tax-saving investment tools, such as an insurance premium, mutual funds, PPFs, etc. It states that you can save up to INR1.5 Lakhs by showing your recurring payment bank statements while filing your tax returns.</p>
      </div>
      </div>
    </>
  )
}

export default LifeInsurance
