import React, {useEffect} from 'react'
import "./aboutUs.css"
import Aos from 'aos';
import 'aos/dist/aos.css'

const AboutUs = () => {
  useEffect(()=> {
    Aos.init({duration: '2000'})
  }, [])
  return (
    <>
      <div className="aboutUs ">
        <div className="aboutUsContainer">
          <div className="aboutImgContainer">
            <img src="Images/AboutUs.avif" alt="Img" className="aboutImg" />
            <h1 className=' text-4xl lg:text-[32pt] lg:leading-[64pt] font-normal absolute'>About us<hr /></h1>
          </div>
          <div className="px-3 discriptionContainer1">

          <div className="mx-4 my-auto descImg">
              <img src="Images/AboutUs2.png" alt="Img" className="my-8 lg:w-[2300px] aboutImg2" data-aos="fade-left"/>
            </div>
            
            <div className="w-auto h-auto mx-auto my-auto discriptionContainer2 ">
              <h4 className='AUH4 font-Medium lg:text-[22pt] lg:leading-[28pt]   md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt]'>Finance means Apna Capital.</h4>
              {/* <h2 className='AUH2'>About Apna Capital Financial Management Services.</h2> */}
              <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>We are one of the largest Corporate DSA of India & fastest emerging Loan Distribution & Financial Management Company in India.</p>
              <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Apna Capital is a leading online lender that helps you to achieve your dream of being homeowners. We’ve brought about a revolution in the industry by offering custom fit loan products from a range of banks and NBFCs. We specialize in providing end-to-end support in the lending lifecycle, including assisting in choosing the product that fits your needs, hassle-free documentation, legal and insurance consulting, and disbursal.</p>

              <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>The philosophy behind Apna Capital is simple: make the complex lending process simpler for the consumer by leveraging innovative technology and through our highly skilled team members. We utilize our state-of-the-art technology to accurately determine the applicant’s loan eligibility which are linked to the credit bureau, provide loan products, and seamlessly integrate with the loan origination system.</p>

              <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Our platform is the simplest and most convenient way you can meet your financing needs. We believe in ensuring a customer experience that is tailor-made for each client. We provide an integrated customer experience that offers a number of products like home loans, personal loans, business loans, insurance, loan against property, working capital, etc.</p>

              <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Apna Capital has established a strong presence in the industry owing to its trust and credibility, transparency, paperless process, and ability to provide quick and easy loans. With vast experience and a track record of excellence, we are a team of extremely talented professionals dedicated to providing a hassle-free lending experience.</p>
            </div>
            
          </div>
          <div className="VisionContainer">
            <div className="ourvision">
              <h1 className='my-10 text-2xl'>Our Vision<hr className='mt-1'/></h1>
              <div className="visionDesc">
                <img src="Images/visionImg2.jpg" alt="Img" className="VisionImg" data-aos="fade-right"/>
                <p className='my-auto visionDesc_p font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]' >By integrating its unmitigated market expertise with the latest trends and developments in digital technology, Apna Capital helps partners find the best financial products most suited to their customers’ needs.</p></div>
            </div>
          </div>
          <hr />
          <div className="my-5 WhatWeDoContainer">
            <h1 className='my-2 text-center'>What we do?</h1>
            <div className="w-auto h-auto WhatWeDo">
              <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Apna Capital is a real time marketplace for loans and credit cards. We employ latest technology, AI, ML & our pan India presence to search for the best available loan offers available on lenders’ app, website or at branch.</p>
              <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>We act as a bridge between lenders and borrowers by educating, facilitating and empowering applicants with the widest choice of financial products in India. You can reach out directly through our website or app.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs
