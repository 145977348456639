import React from 'react'
import './investment.css'

const Investment = () => {
  return (
    <div className='Investment'>
      <div className=" InvestmentContainer">
        <div className="InvestmentImgContainer">
          <img src="Images/investment-backgroundI.jpg" alt="Img" className="InvestmentImg" />
          <h1 className='text-4xl lg:text-[32pt] lg:leading-[64pt] font-normal '>Investment<hr /></h1>
        </div>
        <div className="InvestmentContainer1">
          <div className="INVST">
            <h4 className=' font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] INVSTH4'><b>What is Investment?</b><hr /></h4>
            <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Investment is an act of funding an asset to churn revenue out of it. This process is also known as "appreciation of assets". After investing in an asset, as time passes, its value is expected to soar with time which in turn provides a higher return price than what was originally invested. In simple words, investment is the method of spending money to acquire more of it over time. The general purpose of buying an asset as a means of investment is not to exploit or consume that asset but to generate more liquidity.</p>
          </div>
        </div>
        <div className="INVST">
          <h4 className=' font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] INVSTH4'><b>How Does an Investment Work?</b><hr /></h4>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>An investment works when you put in your money on an asset through various mediums and platforms available, set it aside, and let it mature over time so as to get a higher return on your initial investment. There are many investment types that are duration specific such as short-term investments, long-term investments, etc.</p>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>It is unlike savings, where the goal is merely short term, and the money's exponential growth rate is slow and steady. Invested funds are entirely dependent on the asset's market value. Therefore, it is highly prudent to understand the annual reports and strategies of a company before investing in it.</p>
        </div>
        <div className="INVST">
          <h4 className=' font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] INVSTH4'><b>Investment Plans</b><hr /></h4>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Make your dream home a reality with our leading home loan services. Urban Money has tied knots with an array of banks providing nimble and hassle-free mortgage loans. Unlock home credit loans with a host of benefits, like low housing loan interest rates and smaller EMIs to space out your payments over a longer tenure.</p>
          <div className="cardContainer">
            <div className="cardContainer1">
              <div className="cardC1">
                <h5>Fixed Deposit<hr /></h5>
                <img src="Images/InvestmentCardLogo.jpg" alt="Img" className="INVSTcardImg" />
              </div>
              <div className="cardC1">
                <h5>Employee Provident<hr /></h5>
                <img src="Images/InvestmentCardLogo.jpg" alt="Img" className="INVSTcardImg" />
              </div>
              <div className="cardC1">
                <h5>National Pension<hr /></h5>
                <img src="Images/InvestmentCardLogo.jpg" alt="Img" className="INVSTcardImg" />
              </div>
            </div>
            <div className="cardContainer2">
              <div className="cardC2">
                <h5>Mutual Funds<hr /></h5>
                <img src="Images/InvestmentCardLogo.jpg" alt="Img" className="INVSTcardImg" />
              </div>              
              <div className="cardC2">
                <h5>Public Provident<hr /></h5>
                <img src="Images/InvestmentCardLogo.jpg" alt="Img" className="INVSTcardImg" />
              </div>              
              <div className="cardC2">
                <h5>National Saving<hr /></h5>
                <img src="Images/InvestmentCardLogo.jpg" alt="Img" className="INVSTcardImg" />
              </div>
            </div>
            <div className="cardContainer3">
            <div className="cardC3">
                <h5>Recurring Deposit<hr /></h5>
                <img src="Images/InvestmentCardLogo.jpg" alt="Img" className="INVSTcardImg" />
              </div>
              <div className="cardC3">
                <h5>Voluntary Provident<hr /></h5>
                <img src="Images/InvestmentCardLogo.jpg" alt="Img" className="INVSTcardImg" />
              </div>
              <div className="cardC3">
                <h5>Senior Citizens<hr /></h5>
                <img src="Images/InvestmentCardLogo.jpg" alt="Img" className="INVSTcardImg" />
              </div>
            </div>
            <div className="cardContainer4">
            <div className="cardC4">
                <h5>Systematic Investment<hr /></h5>
                <img src="Images/InvestmentCardLogo.jpg" alt="Img" className="INVSTcardImg" />
              </div>
              <div className="cardC4">
                <h5>Equity Linked<hr /></h5>
                <img src="Images/InvestmentCardLogo.jpg" alt="Img" className="INVSTcardImg" />
              </div>
            </div>
          </div>
        </div>
        <div className="INVST">
          <h4 className=' font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] INVSTH4'><b>Types of Investment</b><hr /></h4>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>There are several types of investment that you can consider. You may choose to invest in just one of these, or you can also choose to diversify your portfolio through asset allocation.</p>
          <h5>Stocks</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Stocks or equities are security that acts as a fraction of a company's ownership. An investor may consider buying these stocks and, depending on the market value of the company, may yield a profitable raise over their initial investment. The stocks are bought and sold over stock exchanges and other private agencies. There are strict government rules and regulations to be followed while making these purchases.</p>
          <h5>Bonds</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Bonds are similar to a loan, where investors provide funds to any companies that need financing. Similar to mortgages, bonds also have characteristics where a borrower's company pays the investor a small recurring payment. Once the maturity period is up, the company will repay the investor his complete amount.</p>
          <h5>Mutual Funds</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Mutual funds are a giant pool of money provided by various investors that go into various companies' shares, bonds, stocks, etc. There are dedicated expert fund managers who move these funds across different organizations shares, bonds or stocks based on their performance, market value, etc. Investors usually get returns based on the contribution they make.</p>
          <h5>ETFs</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Much like mutual funds, an ETF or an Exchange Traded Fund is a compilation of money sent in by various investors managed by expert fund managers. However, unlike mutual funds, an ETF can be bought on a stock exchange platform like stocks and shares.</p>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Banks in India also provide investors with an opportunity to invest their money in mutual funds and ETFs. Each bank may have its own SIPs or Systematic Investment Plans that investors can choose from.</p>
          <h5>Options</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Options are an exotic kind of investment where an individual can hold an agreement. In this agreement, they will have the right to buy or sell a company's underlying stocks, bonds, shares, etc., at a fixed price. They are worth considering but only after assessing all the risks involved.</p>
          <h5>Commodity Futures</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>It is an instrument that allows a certain buyer the right to buy a commodity at a preset cost and date. This contract can be used to reserve the right to buy the commodity on a future date with the current prices. Buyers who have enough expertise on a commodity and its market value can mostly go for such investments as they will be able to take a calculated call on the commodity, irrespective of its future price fluctuations. The trading happens in an exchange with an approved broker.</p>
          <h5>Cryptocurrencies</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>In recent times, cryptocurrencies have been one of the most popular choices to invest in. They became an ushering sensation that attracted more people to invest with the expectation of huge returns. Crypto is a high-risk, high-return deal that not only gave rise to thousands of new cryptocurrencies but also to various investment platforms and apps. Names such as Bitcoins, Avax, Lucky Block or L Block, Ethereum, etc., are some of the popular cryptos these days.</p>
          <h5>Annuities</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Annuities are an opportunity to receive a steady stream of income for a specific period of time. Generally, investors like to have a steady retirement income every month. The contract is signed between an investor and an insurance company where the latter agrees to pay the former a recurring payment. Although annuities offer tax benefits, it may be mandatory to pay a small tax at the start of the settlement.</p>
          <h5>Security Futures</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>A futures contract is essentially a contract between a producer and a consumer who happens to have a keen observation regarding a certain commodity and is able to speculate on its future price hike. A futures contract grants buyers to enter an agreement with the producer that they will buy the commodity at a future date but with the current price. Now, if the price of the commodity actually rises, that means the buyers incurred some profits over it. This type of contract has made its mark in the investment industry. Although a little complex, futures are also a great way to invest.</p>
        </div>
        <div className="INVST">
          <h4 className=' font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] INVSTH4'><b>Why is Investment Important?</b><hr /></h4>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Commodity prices and the cost of living, in general, have witnessed an upward trend over the years. Everyone can agree that the prices at which commodities were sold 20 years ago are not the same anymore. Under such circumstances, idle savings in the bank do not prove to be enough, making way for the need for investments to create a financially stable present and future.</p>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>The main reason investment is massively important is that one has to make sure they are financially secure. Moreover, the money saved by a person has the potential to be much more by getting involved in the market cash flow.</p>
          {/* <ul><li>Investment helps in compounding the money over time.</li>
            <li>Smart investment can help achieve goals pertaining to an upgraded standard of living.</li>
            <li>Investing motivates a person to think about their future and instil a sense of cultivating a disciplined lifestyle.</li>
            <li>Smart Investment helps increase a consumer's purchasing power in the dawn of inflation and increased cost of commodities.</li>
            <li>Investments also provide tax benefits which can help a person save a huge amount of money.</li>
          </ul> */}
        </div>
        <div className="INVST">
          <h4 className=' font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] INVSTH4'><b>Alternative Investments</b><hr /></h4>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>There are quite a few unconventional investment forms usually designed for individuals with high net worth. This is mostly because these investments require higher minimum fund sourcing and fewer authority regulations.</p>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>They aren't generally offered on regular stock exchange markets and are less advertised. An alternative investment is pretty complex to value, not easily convertible to liquid money and is considered highly risky. Some of the most common types of unconventional investments are as follows:</p>
          <ul className='grid place-items-start ml-[75px] list-disc font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>
            <li >Private Equity</li>
            <li >Hedge Funds</li>
            <li >Commodities</li>
            <li >Venture Capital</li>
            <li >Art and Antiques</li>
            <li >Real Estate</li>
            <li >Derivatives Contracts</li>
            <li >Managed Futures</li>
          </ul>
        </div>
        
        {/* <div className=" BenifitsContainer LIC">
          <h3 className="LIC-Heading">How Do I Start Investing?</h3><hr />
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>To start investing, it is extremely important to get a thorough understanding of the market and its related dynamics. You need to do a lot of research on the assets you are or will be investing in to infer its positive market impact in the future loINVSTally. Here are a few handy tips for doing so and cultivating smart investing.</p>
          <ul >
            <li><b>Understand the Market:</b><br />The first and foremost step will always be to educate yourself. Invest in yourself and in your upgrading of skills. Engage yourself in learning about the subject matter that you will be stepping in. When investing, it is imperative that you have a basic idea of how a certain company functions in the market and how future demand for its products will affect the company's share prices.</li>
            <li><b>Decide on the Risk Factor:</b><br /> There are many kinds of investments where risks are varied. The only unavailable combination is low risk and high return. Except for that, you need to decide what kind of risk you are willing to take. It is advised to go for a low-risk, low return plan, which is generally taken for a long-term investment.</li>
            <li><b>Life Goal:</b><br />Make a life goal or objective for your future. It may be anything like wanting a relaxed retirement, wanting the best education for your kid, etc. Whatever you feel is worth setting aside your money for, think about it and possibly make a note.</li>
            <li><b>Allocate Your Assets:</b><br />Another important tip is to allocate your assets. This means you can divide your monetary assets and allocate them to different kinds of investments. It is always believed that never keep all your eggs in one basket for a good reason. Allocating assets will not only help you ensure a good return but also help you in diversifying your portfolio.</li>
          </ul>
        </div> */}
        <div className="INVST">
          <h4 className=' font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt] INVSTH4'><b>Which Investment Option Should You Choose?</b><hr /></h4>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Investment should always be chosen after assessing how much risk you are willing to take and what your goals in life are. Out of the many plans available, the best investment plan will completely depend on your expectations for the future. A few suggestions for the best investment plans are as follows.</p>
          <h5>Direct Equity</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>An easy investment option to consider is allocating your funds to direct equity such as stocks and bonds. Although the return on investment here is not guaranteed, it has a higher chance of a decent return in the long run.</p>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Investing in stocks means that you earn a part of ownership in a company, and whatever profit the company makes will affect the value of your investment. And it goes without saying that a thorough analysis of the company's performance over the years should be done. Stocks in India, which are proposed via stock exchanges, are purchased through a Demat Account.</p>
          <h5>National Pension Scheme</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Administered and managed by the PFRDA (Pension Fund Regulatory and Development Authority), the National Pension Scheme is a pension system that aims to provide a regular income to senior citizens post-retirement.</p>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>The scheme was initially introduced for government servants. However, later it was opened to the public as well. The only requirement for investing in the National Pension Scheme is to have Indian citizenship.</p>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Moreover, investors are also required to be in an age group of 18 to 70 with a sound mind. The National Pension Scheme is a great option for a long-term investment with tax benefits. The other similar scheme released by the government of India is the Senior Citizens Savings Scheme (SCSS).</p>
          <h5>Bank Fixed Deposits</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Another popular go-to investment option devoid of any market risks and also guarantees regular interest returns is bank fixed deposits. There are tax benefits, and also special interest rates offered to senior citizens. The interest rates usually vary among banks due to competition.</p>
          <h5>Real Estate</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Real estate is a major market for investment where individuals buy and sell/rent properties inviting chunks of income their way. This is a major investment choice because a property bought can go through massive value fluctuations depending on the development of the locality or towns. There are several hot spots around metropolitan areas that are investor-friendly in real estate.</p>
          <h5>Initial Public Offerings</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>Whenever a private company offers its stocks up for grabs to the public, they have to initiate an Initial Public Offerings or IPO. Initial Public Offerings are basically stocks and shares wherein, to generate some funds for their operations, companies allow the public to invest for asset appreciation.</p>
          <h5>Public Provident Fund</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>The Public Provident Fund (PPF) is as popular as it is safe. It is a go-to long-term investment plan for individuals that prefer a low-risk, low-return factor when investing. PPF is generally considered for a tenure of 15 years but can be renewed in chunks of 5 years. Investors get the option of depositing a minimum as INR 500 a month. The interest rate usually stays around 7%.</p>
          <h5>Mutual Funds</h5>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>An investment type typically considered by both beginners and experts is also generally a safer bet to regard. The main reasons for its popularity are that mutual funds are managed by expert investors that analyse and monitor market conditions and invest accordingly.</p>
          <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>The fund managers compile money from several investors and engage the money with various assets. Additionally, in mutual funds, investors are free to choose their investment amount, which can be as low as INR 500 a month. They are also easily redeemable and highly liquid.</p>
        </div>
      </div>
    </div>
  )
}

export default Investment
