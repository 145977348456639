import React,{useEffect, useRef, useState} from 'react'
import './whyUs.css'

const WhyUs = () => {
  const [loanAmount, setLoanAmount] = useState(1000000);
  const [interestRate, setInterestRate] = useState(10);
  const [loanTenure, setLoanTenure] = useState(240);
  const [emi, setEmi] = useState(9650)
  const [totalIntrest, setTotalIntrest] = useState(1316052);
  const [totalAmount, setTotalAmount] = useState(2316052);


  const calculateBtnClick = (loanAmount, interestRate, loanTenure) => {
    const emi = calculateEMI(loanAmount, interestRate, loanTenure);
    setEmi(Math.round(emi))
    setTotalIntrest(Math.round(emi*loanTenure*12 - loanAmount));
  };

  useEffect(()=>{
    setTotalAmount(totalIntrest+parseInt(loanAmount))
  },[totalIntrest])
  

  const calculateEMI = (loanAmount, interestRate, loanTenure) => {
    const interest = interestRate / 12 / 100;
    const emi =
      loanAmount *
      interest *
      (Math.pow(1 + interest, loanTenure*12) /
        (Math.pow(1 + interest, loanTenure*12) - 1));
    return emi;
  };

  return (
    <>
    <div className="loan-calculator">
    <div className="top">
      <h2>Loan Calculator</h2>

      <form action="#">
        <div className="group">
          <div className="title">Amount</div>
          <input type="text" value={loanAmount} className="loan-amount" onChange={(e)=> setLoanAmount(e.target.value)} />
        </div>

        <div className="group">
          <div className="title">Interest Rate</div>
          <input type="text" value={interestRate} className="interest-rate" onChange={(e)=> setInterestRate(e.target.value)}/>
        </div>

        <div className="group">
          <div className="title">Tenure (in years)</div>
          <input type="text" value={loanTenure} className="loan-tenure" onChange={(e)=> setLoanTenure(e.target.value)}/>
        </div>
      </form>
    </div>

    <div className="result">
      <div className="left">
        <div className="loan-emi">
          <h3>Loan EMI</h3>
          <div className="value">{emi}</div>
        </div>

        <div className="total-interest">
          <h3>Total Interest Payable</h3>
          <div className="value">{totalIntrest}</div>
        </div>

        <div className="total-amount">
          <h3>Total Amount</h3>
          <div className="value">{totalAmount}</div>
        </div>

        <button className="calculate-btn" onClick={()=>calculateBtnClick(loanAmount, interestRate, loanTenure)}>Calculate</button>
      </div>
      </div>
    </div>
      </>
  )
}

export default WhyUs
