import React, { useState } from "react";
import "./contactUs.css";
import axios from "axios";

const ContactUs = () => {
  const [service, setService] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loan, setLoan] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleOptionChange = (event) => {
    setService(event.target.value);
  };

  const handleLoanChange = (event) => {
    setLoan(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the form data to the backend
      console.log(`${process.env.REACT_APP_BACKEND_URL}/api/apply`);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/apply`, {
        name,
        email,
        phone,
        service,
        loan,
        message,
      });

      console.log(response.data); // Log the response from the backend

      // Reset the form fields after successful submission
      setService("");
      setName("");
      setEmail("");
      setPhone("");
      setLoan("");
      setMessage("");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <section className="contact-form-sec contactUs">
        <div className="container">
          <div className="main-heading text-center">
            <h1 className="text-center text-4xl mb-4 md:text-5xl font-bold ">
              Contact Us
            </h1>
            <p>
              Feel free to reach out with any questions and get direct answers
              from our Apna Capital specialists.
            </p>
          </div>
          <div className="contact-form-box ">
            <form action="" onSubmit={handleSubmit}>
              <div className="field-row ">
                <p>
                  <label>
                    Enter Your Name <span className="required">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    className="w-[300px]"
                    name="Cname"
                    id="Cname"
                    placeholder="Name"
                    value={name} // Set value to the name state
                    onChange={(e) => setName(e.target.value)}
                  />
                </p>
                <p>
                  <label>
                    Enter Your Email <span className="required">*</span>
                  </label>
                  <br />
                  <input
                    className="w-[300px]"
                    type="email"
                    name="Cemail"
                    id="Cemail"
                    placeholder="Email"
                    value={email} // Set value to the email state
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </p>
              </div>
              <div className="field-row ">
                <p>
                  <label>
                    Enter Your Number <span className="required">*</span>
                  </label>
                  <br />
                  <input
                    className="w-[300px]"
                    type="text"
                    name="Cnumber"
                    id="Cnumber"
                    placeholder="Contact No."
                    value={phone} // Set value to the phone state
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </p>
                <p>
                  <label>
                    Select Type <span className="required">*</span>
                  </label>
                  <br />
                  <select
                    className="w-[300px] "
                    value={service}
                    onChange={handleOptionChange}
                    id="services"
                  >
                    <option value="Select">Select</option>
                    <option value="Loans">Loans</option>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Investment">Investment</option>
                    <option value="Insurance">Insurance</option>
                  </select>
                </p>
              </div>

              {service === "Loans" && (
                <p>
                  <label>
                    Select Loan type <span className="required">*</span>
                  </label>
                  <br />
                  <select
                    name="Loan"
                    id="LoanC"
                    className="Loan w-[300px] "
                    value={loan}
                    onChange={handleLoanChange}
                  >
                    <option value="Select">Select</option>
                    <option value="Home Loan">Home Loan</option>
                    <option value="Car Loan">Car Loan</option>
                    <option value="Personal Loan">Personal Loan</option>
                    <option value="Business Loan">Business Loan</option>
                    <option value="Mortage Loan">Mortage Loan</option>
                    <option value="Gold Loan">Gold Loan</option>
                    <option value="Vehicle Loan">Vehicle Loan</option>
                    <option value="Machinery Loan">Machinery Loan</option>
                  </select>
                </p>
              )}

              <div className="px-5">
                <label>
                  Enter your message
                </label>
                <br />
                <textarea
                  name="TextArea"
                  id="textarea"
                  cols="30"
                  rows="4"
                  resize="none"
                  placeholder="Your Message here"
                  className=" w-full"
                  value={message} // Set value to the message state
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="form-bottom-box">
                <button type="Submit" className="btn bg-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <div className="gestart-footer-sec ">
        <div>
          <div>
            <h1 className="text-xl md:text-4xl xl:text-5xl font-semibold mb-4">
              Get Started With Apna Capital Today
            </h1>
            <p className="md:text-xl md:mb-4">
              We would love to hear from you! Call Or Mail us on
            </p>
            <p className="text-start md:text-2xl">
              MOBILE -&nbsp;{" "}
              <span>
                <a href="tel:+919111092808">+91 9111092808</a>&nbsp;&nbsp;
                <a href="tel:+919111827672"> +91 9111827672</a>
              </span>
            </p>
            <p className="text-start md:text-2xl">
              EMAIL -
              <a href="mailto:apnacapitalglobalfinance@gmail.com" className="mailA">
                {" "}
                apnacapitalglobalfinance@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
