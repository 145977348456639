import React from "react";
import "./navbar.css";
import { KeyboardArrowDown } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [checked, setChecked] = useState(false)
  
  const logo = 'Images/ApnaLogo.png'
  const handleClick = () => {
    setChecked(!checked)
  }

  return (
    <>
      <header >
        <div className="headerNAV">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              style={{ width: "98px", marginLeft: "1px", marginTop: "-8px" }}
            />
          </Link>
          <input type="checkbox" id="menu-bar" checked={checked} onClick={() => handleClick()}/>
          <label htmlFor="menu-bar">
            <div className="menudiv">
              <MenuIcon className="Menu" />
            </div>
          </label>
          <nav className="navbar">
            <ul>
              <Link to="/">
                <li className="NavItem" onClick={()=> handleClick()}>Home</li>
              </Link>
              <Link to="/AboutUs">
                <li className="NavItem" onClick={()=> handleClick()}>About us</li>
              </Link>
              {/* <Link to="/" style={{textDecoration:"none"}}> 
              <li><a href="Calculator" className='NavItem'>Loan Calculator</a></li>
              </Link> */}
              <li>
                <p href="/" className="NavItem">
                  Loan
                  <KeyboardArrowDown />
                </p>
                <ul className="LoanItemUl" onClick={()=> handleClick()}>
                  <Link to="/HomeLoan">
                    <li className="LoanItem">Home Loan</li>
                  </Link>
                  <Link to="/MortgageLoan">
                    <li className="LoanItem">Mortgage Loan</li>
                  </Link>
                  <Link to="/PersonalLoan">
                    <li className="LoanItem">Personal Loan</li>
                  </Link>
                  <Link to="/CarLoan">
                    <li className="LoanItem">Car Loan</li>
                  </Link>
                  <Link to="/BusinessLoan">
                    <li className="LoanItem">Business Loan</li>
                  </Link>
                  <Link to="/Education">
                    <li className="LoanItem">Education Loan</li>
                  </Link>
                  <Link to="/Overdraft">
                    <li className="LoanItem">Overdraft Loan</li>
                  </Link>
                  <Link to="/GoldLoan">
                    <li className="LoanItem">Gold Loan</li>
                  </Link>
                  <Link to="/VehicleLoan">
                    <li className="LoanItem">Vehicle Loan</li>
                  </Link>
                  <Link to="/MachineryLoan">
                    <li className="LoanItem">Machinery Loan</li>
                  </Link>
                </ul>
              </li>
              <Link to="/Investment">
                <li className="NavItem" onClick={()=> handleClick()}>Investment</li>
              </Link>
              {/* <Link to="/CreditCard">
                <li className="NavItem" onClick={()=> handleClick()}>Credit Card</li>
              </Link> */}
              <Link to="/Insurance">
                <li onClick={()=> handleClick()}>
                  <p className="NavItem">
                    Insurance
                    <KeyboardArrowDown />
                  </p>
                  <ul className="LoanItemUl">
                    <Link to="/LifeInsurance">
                      <li className="LoanItem" onClick={()=> handleClick()}>Life Insurance</li>
                    </Link>
                    <Link to="/GeneralInsurance">
                      <li className="LoanItem" onClick={()=> handleClick()}>Genaral Insurance</li>
                    </Link>
                  </ul>
                </li>
              </Link>
              <Link to="/ContactUs">
                <li className="NavItem" onClick={()=> handleClick()}>Contact us</li>
              </Link>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Navbar;
