import React from 'react';
import './homeLoan.css';
import {useNavigate} from 'react-router-dom';

const Loans = (props) => {
  const navigate = useNavigate();

  const ApplyClick = () =>{
    navigate('/ContactUs')
  }

  return (
    <>
      <div className=" HomeLoan">
        <div className="HomeLoanContainer">
          {/* <div className="HomeLoanImgContainer">
            <img src={props.bgImg} alt="Img" className={`HLImg ${props.MT}`} />
          </div> */}
            {/* <h1>Home Loan<hr /></h1> */}
          <div className="HomeLoanContainer1">
            <div className="HomeLoanContainer2">
              <h1 className='my-4 font-Medium lg:text-[22pt] lg:leading-[28pt] text-[#1f2360]  md:text-[16pt] md:leading-[24pt] text-[14pt] leading-[20pt] HLH1'>{props.Heading}<hr /></h1>
              <p className='mb-3 font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>{props.Para1}</p>
              <p className='mb-3 font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>{props.Para2}</p>
              <p className='mb-3 font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>{props.Para3}</p>
              <p className='mb-3 font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]'>{props.Para4}</p>
              {/* <p>{props.Para5}</p> */}
            </div>
            <div className="HLSideImg">
              <img src={props.SideImg} alt="Img" className="HLImg2" />
            </div>
          </div>
            <button className='ApplyBtn' onClick={ApplyClick}>Apply Now</button>
        </div>
      </div>
    </>
  )
}

export default Loans
