import React, { useState, useEffect } from 'react'
import './creditCard.css';
import { useNavigate } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { CCArray } from '../../ImgArray'
import './creditCard.css';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const CreditCard = () => {
  useEffect(() => {
    Aos.init({ duration: 2500 })
  }, [])
  const navigate = useNavigate();

  const [swiper, setSwiper] = useState(null);

  const nexto = () => {
    swiper.slideNext();
  };
  const prevo = () => {
    swiper.slidePrev();
  };

  const ApplyClick = () => {
    navigate('/ContactUs')
  }
  const odd = "odd";
const even = "even";
  return (
    <>
      <div className='CreditCard'>
        <div className="CCtopContainer">
          <div className='CCtop'>
            <h2 className='CCtopH1'>Credit Card</h2>
            <p className='CCtopP'>Get credit cards in India that are best matched to your credit profile</p>
            <div className="CCtop2">
              <img src="Images/CreditCardBg.jpg" alt="" className='CCtopImg' data-aos="fade-right" />
              <div className="SideImgContainer">
                <img src="Images/FirstSelectCard.webp" alt="" className='CCSideImg Img1' />
                <img src="Images/flipkartCreditCard.jpg" alt="" className='CCSideImg Img2' />
                <img src="Images/SBICreditcard.webp" alt="" className='CCSideImg Img3' />
              </div>
            </div>
          </div>
        </div>
        <div className="CardContainer">
          <img src="Images/PrevBtn.jpg" alt="" className="PrevBtn" onClick={prevo} />
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={3}
            // navigation
            pagination={{ clickable: true }}
            onSwiper={(s) => {
              console.log("initialize swiper", s);
              setSwiper(s);
            }}
          >
            {CCArray.map((e, index) => (
              <SwiperSlide>
                <div className={`Card ${index%2 === 0 ? odd : even}`} key={index}>
                  <img src={e.CCImg} alt="" className='CardImg'/>
                  <h5 className='CCHeading'>{e.CCHeading}</h5>
                  <p>Annual fee  :  {e.Annual_fee}</p>
                  <p>Joining fee  :  {e.Joining_fee}</p>
                  <button className='CardBtn' onClick={ApplyClick}>Apply</button>
                </div>
              </SwiperSlide >
            ))}
          </Swiper>
          <img src="Images/NextBtn.jpg" alt="" className="NextBtn" onClick={nexto} />
        </div>
        {/* <div className="CardContainer">
          <img src="Images/PrevBtn.jpg" alt="" className="PrevBtn" />
          <div className="Card one">
            <img src="Images/flipkartCreditCard.jpg" alt="" className='CardImg' />
            <h5>Flipkart Axis Bank Credit Card</h5>
            <p>Annual fee  :  Rs. 500, Waived on Spends of Rs. 2 Lakh</p>
            <p>Joining fee  :  Rs. 500+GST</p>
            <button className='CardBtn' onClick={ApplyClick}>Apply</button>
          </div>
          <div className="Card two">
            <img src="Images/IDFCCreditCard.webp" alt="" className='CardImg' />
            <h5>First Millenia Card</h5>
            <p>Annual fee  :  Life Time Free</p>
            <p>Joining fee  : Life Time Free</p>
            <button className='CardBtn' onClick={ApplyClick}>Apply</button>
          </div>
          <div className="Card three">
            <img src="Images/SBICreditcard.webp" alt="" className='CardImg' />
            <h5>Simply CLICK SBI Credit Card</h5>
            <p>Annual fee  :  Rs. 499</p>
            <p>Joining fee  :  Rs. 499</p>
            <button className='CardBtn' onClick={ApplyClick}>Apply</button>
          </div>
          <img src="Images/NextBtn.jpg" alt="" className="NextBtn" />
        </div> */}
        <div className="TypeOfCard">
          <h2>Type Of Card</h2>
          <div className="TocItemContainer">
            <div className="TOCItem">
              <img src="Images/cart.jpg" alt="" className='TOCImg' />
              <p className="TOCDesc">Shopping</p>
            </div>
            <div className="TOCItem">
              <img src="Images/fuel.jpg" alt="" className='TOCImg' />
              <p className="TOCDesc">Fuel</p>
            </div>
            <div className="TOCItem">
              <img src="Images/gift.jpg" alt="" className='TOCImg' />
              <p className="TOCDesc">Reward</p>
            </div>
            <div className="TOCItem">
              <img src="Images/hands.jpg" alt="" className='TOCImg' />
              <p className="TOCDesc">Business Credit Cards</p>
            </div>
            <div className="TOCItem">
              <img src="Images/plane.jpg" alt="" className='TOCImg' />
              <p className="TOCDesc">Travel</p>
            </div>
            <div className="TOCItem">
              <img src="Images/running.jpg" alt="" className='TOCImg' />
              <p className="TOCDesc">Lifestyle</p>
            </div>
            <div className="TOCItem">
              <img src="Images/cashback.jpg" alt="" className='TOCImg' />
              <p className="TOCDesc">Cashback</p>
            </div>
            <div className="TOCItem">
              <img src="Images/secured.jpg" alt="" className='TOCImg' />
              <p className="TOCDesc">Secured Credit Card</p>
            </div>
          </div>
        </div>
        <div className="CreditCardBenifit">
          <h2>Credit Card Features and Benifit </h2>
          <div className="CCBenifitContainer">
            <div className="CCBenifit">
              <img src="Images/CashBackBenifit.jpg" alt="" className='CCBImg' />
              <h5 className='CCBenifitH5'>Cashback</h5>
              <p className="CCBenifitDesc">Lucrative cashback offers with an instant amount credit feature.</p>
            </div>
            <div className="CCBenifit">
              <img src="Images/LowIntrest.jpg" alt="" className='CCBImg' />
              <h5 className='CCBenifitH5'>Low Interest</h5>
              <p className="CCBenifitDesc">Enjoy low-interest rates, discounts, and cashback on your credit card payments.</p>
            </div>
            <div className="CCBenifit">
              <img src="Images/Travel.jpg" alt="" className='CCBImg' />
              <h5 className='CCBenifitH5'>Travel</h5>
              <p className="CCBenifitDesc">Make your travel hassle-free with credit cards that offer extensive rewards.</p>
            </div>
            <div className="CCBenifit">
              <img src="Images/Reward.jpg" alt="" className='CCBImg' />
              <h5 className='CCBenifitH5'>Rewards</h5>
              <p className="CCBenifitDesc">Enjoy special birthday rewards and cross-payment thresholds on specific categories.</p>
            </div>
            <div className="CCBenifit">
              <img src="Images/ZeroP.jpg" alt="" className='CCBImg' />
              <h5 className='CCBenifitH5'>Zero Percent</h5>
              <p className="CCBenifitDesc">Explore your options for an accelerated reward credit card with zero per cent interest.</p>
            </div>
            <div className="CCBenifit">
              <img src="Images/BalT.jpg" alt="" className='CCBImg' />
              <h5 className='CCBenifitH5'>Balance Transfer</h5>
              <p className="CCBenifitDesc">Get top-up and balance transfer facilities on a credit card with attractive rates.</p>
            </div>
          </div>
        </div>
        <div className="QuesContainer">
          <div className="Ques">
            <h4>What is Credit Card?</h4>
            <p>A credit card is a financial instrument that has a pre-loaded balance that the cardholder can use to make transactions and pay for them later. The card issuer will let you pay off the balance fully, interest-free, for up to 50 days from the date of payment. To avoid any fines, the cardholder can pay the minimum amount due (which can be anywhere between 5% to 10% on the total amount owed). The balance, however, will be carried forward to the next month, and interest set by the credit card company will be levied.</p>
          </div>
          {/* <div className="Ques">
            <h4>How does A Credit Cards work?</h4>
            <p>A credit card gives you more time to pay for your purchases. Every time you purchase on your credit card, you are in effect borrowing that money from the credit card issuer until you pay it back at the end of the month. Here are some things to know before you get your own credit card</p>
            <ol>
              <li>Credit card companies give credit cards to individuals who have a consistent income</li>
              <li>If you have a good credit score, you can get a credit card easily</li>
              <li>You can obtain a credit card against your fixed deposit.</li>
              <li>As a credit card holder, you will be allocated a credit limit, which will be 3 to 5 times of your net monthly salary. This will vary across different banks.</li>
              <li>Although a credit card is provided by popular banks, the payment gateway is facilitated by Visa, MasterCard and RuPay. However, these payment facilitators cannot set other terms and conditions on the card. Features such as minimum due amount, interest rate, reward points, etc., are fixed by credit card issuers.</li>
            </ol>
          </div> */}
          <img src="Images/FactsImg.jpg" alt="" className='QueImg' />
        </div>
        <div className="QuesContainer2">
          <div className="Ques">
            <h4>How to Apply For A Credit Card?</h4>
            <p>After doing the eligibility check, you can apply for the card through the following ways.</p>
            <h4>Online Apply For credit card By Raising The Request Through Apna Capital Website:</h4>
            <p>You can compare multiple cards from various banks on Apna Capital and finally choose the best credit card suited for your needs. To apply, simply follow the steps below:</p>
            <ol>
              <li>Visit the website to check the various types of credit cards available.</li>
              <li>After choosing the card you want to apply for, go to the application page.</li>
              <li>Enter some basic details and click submit. An OTP will be dispatched to your mobile.</li>
              <li>Enter this OTP for verification</li>
              <li>Click on ‘view eligible offers and apply’</li>
            </ol>
          </div>
          {/* <div className="Ques">
            <h4>Apply for the credit card online through the official website of the bank:</h4>
            <p>You can go to the official website of the bank and do an online credit card apply</p>
            <ul>
              <li>Take a look at the list of credit cards offered by the lender and compare their features</li>
              <li>Select the credit card that is best suited for your spending habits</li>
              <li>Fill in your personal and contact details in the application form</li>
              <li>Upload the required documents if necessary</li>
              <li>Submit the form</li>
              <li>After you have submitted the form, an executive from the bank will contact you for verification purposes and push the application for approval.</li>
            </ul>
            <p>SMS: Most banks let customers contact them through SMS facility. You can send an SMS to the respective bank as per the syntax provided to get in touch with you with the application process.</p>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default CreditCard
