import React from 'react'
import "./footer.css"
import FacebookIcon from '@mui/icons-material/Facebook';
import { Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {useNavigate} from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

  const ApplyClick = () =>{
    navigate('/ContactUs')
  }
  const LoanClick = () =>{
    navigate('/HomeLoan')
  }
  const InsuraceClick = () =>{
    navigate('/Insurance')
  }
  const InvestClick = () =>{
    navigate('/Investment')
  }
  const CreditClick = () =>{
    navigate('/CreditCard')
  }
    return (
        <>
        <div className='Footer'>
            <div className="FooterContainer padding">
                <div className="name"><h4>Apna Capital Global Finance.</h4>
                    <p>We are one of the Largest Corporate DSA & Fastest Loan Distribution Company in India.</p>
                    <a href="https://www.facebook.com/apnacapitalfinance" target='_blank'>
                    <span className='socialIcon'><FacebookIcon /></span>
                    </a>
                    <a href="https://www.instagram.com/apna_capitalfinance" target='_blank'>
                    <span className='socialIcon'><Instagram /></span>
                    </a>
                    <a href="https://www.linkedin.com/in/apna-capital-global-finance" target='_blank'>
                    <span className='socialIcon'><LinkedIn /></span>
                    </a>
                    <a href="https://twitter.com/Apna_Capital" target='_blank'>
                    <span className='socialIcon'><Twitter /></span>
                    </a>
                </div>
                <div className="services padding" ><h4>Services</h4>
                    <div className="serviceItem" onClick={LoanClick}>Loans</div>
                    <div className="serviceItem" onClick={InsuraceClick}>Insurance</div>
                    {/* <div className="serviceItem" onClick={CreditClick}>Credit Card</div> */}
                    <div className="serviceItem" onClick={InvestClick}>Investment</div>
                </div>
                <div className="CustomerSupport padding"><h4>Customer Support</h4>
                    <div className="CSItems">Terms Of Use</div>
                    <div className="CSItems">Privacy Policy</div>
                    <div className="CSItems">Disclaimer</div>
                    <div className="CSItems" onClick={ApplyClick}>Contact Us</div>
                </div>
                <div className="contactInfo padding"><h4>Contact Info</h4>
                    <div className="CIItem">
                        <div className="Address">
                            <LocationOnIcon className="ContactIcon" />
                        <a href="https://www.google.com/maps/place/Sarvoday+Prime/@22.7306448,75.8810966,16.36z/data=!4m6!3m5!1s0x3962fd49c0e46759:0x89cbeeb1bc64cfe!8m2!3d22.7304473!4d75.884802!16s%2Fg%2F11l4v4tw6f?entry=ttu" target="_blank">
                            <span className='addressDesc'>108, First Floor, Sarvoday Prime Building, 29/2/C,  New Palasia, Indore (M.P.)</span></a>
                        </div>
                        <div className="phoneNo">
                            <PhoneIcon className='ContactIcon' />
                            <a href="tel:+919111092808">+919111092808</a>
                            <a href="tel:+919111827672"> +919111827672</a>
                        </div>
                        <div className="Whatapp">
                            <a href="https://api.whatsapp.com/send/?phone=919111092808&text&type=phone_number&app_absent=0" target='_blank'>
                            <WhatsAppIcon className='ContactIcon' />
                            <span>8234055370</span></a>
                        </div>
                        <div className="email">
                            <MailOutlineIcon className='ContactIcon' />
                            <a href="mailto:apnacapitalglobalfinance@gmail.com" className='mailA'>apnacapitalglobalfinance@gmail.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copywrite">
            &copy;  2023, Apna Capital Global Finance. All Rights Reserved.
            </div>
            </div>
        </>
    )
}

export default Footer