export const Images = [
    {
        PartnerImg: "Images/1.png"
    },
    {
        PartnerImg: "Images/2.png"
    },
    {
        PartnerImg: "Images/3.png"
    },
    {
        PartnerImg: "Images/4.png"
    },
    {
        PartnerImg: "Images/5.png"
    },
    {
        PartnerImg: "Images/6.png"
    },
    {
        PartnerImg: "Images/7.png"
    },
    {
        PartnerImg: "Images/8.png"
    },
    {
        PartnerImg: "Images/9.png"
    },
    {
        PartnerImg: "Images/10.png"
    },
    {
        PartnerImg: "Images/11.png"
    },
    {
        PartnerImg: "Images/12.png"
    },
    {
        PartnerImg: "Images/13.png"
    },
    {
        PartnerImg: "Images/14.png"
    },
    {
        PartnerImg: "Images/15.png"
    },
    {
        PartnerImg: "Images/16.png"
    },
    {
        PartnerImg: "Images/17.png"
    },
    {
        PartnerImg: "Images/18.png"
    },
    {
        PartnerImg: "Images/19.png"
    },
    {
        PartnerImg: "Images/20.png"
    },
    {
        PartnerImg: "Images/21.png"
    },
    {
        PartnerImg: "Images/22.png"
    },
    {
        PartnerImg: "Images/23.png"
    },
    {
        PartnerImg: "Images/24.png"
    },
    {
        PartnerImg: "Images/25.png"
    },
    {
        PartnerImg: "Images/26.png"
    },
    {
        PartnerImg: "Images/27.png"
    },
    {
        PartnerImg: "Images/28.png"
    },
    {
        PartnerImg: "Images/29.png"
    },
    {
        PartnerImg: "Images/30.png"
    },
    {
        PartnerImg: "Images/31.png"
    },
    {
        PartnerImg: "Images/32.png"
    },
    {
        PartnerImg: "Images/33.png"
    },
    {
        PartnerImg: "Images/34.png"
    },
    {
        PartnerImg: "Images/35.png"
    },
    {
        PartnerImg: "Images/36.png"
    },
    {
        PartnerImg: "Images/37.png"
    },
    {
        PartnerImg: "Images/38.png"
    },
    {
        PartnerImg: "Images/39.png"
    },
    {
        PartnerImg: "Images/40.png"
    },
    {
        PartnerImg: "Images/41.png"
    },
    {
        PartnerImg: "Images/42.png"
    },
    {
        PartnerImg: "Images/43.png"
    },
    {
        PartnerImg: "Images/44.png"
    },
    {
        PartnerImg: "Images/45.png"
    },
    {
        PartnerImg: "Images/46.png"
    },
    {
        PartnerImg: "Images/47.png"
    },
    {
        PartnerImg: "Images/48.png"
    },
    {
        PartnerImg: "Images/49.png"
    },
    {
        PartnerImg: "Images/50.png"
    },
    {
        PartnerImg: "Images/51.png"
    },
    {
        PartnerImg: "Images/52.png"
    },
    {
        PartnerImg: "Images/53.png"
    },
    {
        PartnerImg: "Images/54.png"
    },
    {
        PartnerImg: "Images/55.png"
    },
    {
        PartnerImg: "Images/56.png"
    },
    {
        PartnerImg: "Images/57.png"
    },
    {
        PartnerImg: "Images/58.png"
    },
    {
        PartnerImg: "Images/59.png"
    },
    {
        PartnerImg: "Images/60.png"
    },
    {
        PartnerImg: "Images/61.png"
    },
    {
        PartnerImg: "Images/62.png"
    },
    {
        PartnerImg: "Images/63.png"
    },
    {
        PartnerImg: "Images/64.png"
    },
    {
        PartnerImg: "Images/65.png"
    },
    {
        PartnerImg: "Images/66.png"
    },
    {
        PartnerImg: "Images/67.png"
    },
    {
        PartnerImg: "Images/68.png"
    },
    {
        PartnerImg: "Images/69.png"
    },
    {
        PartnerImg: "Images/70.png"
    },
    {
        PartnerImg: "Images/71.png"
    },
    {
        PartnerImg: "Images/72.png"
    },
    {
        PartnerImg: "Images/73.png"
    },
    {
        PartnerImg: "Images/74.png"
    },
    {
        PartnerImg: "Images/75.png"
    },
    {
        PartnerImg: "Images/76.png"
    },
    {
        PartnerImg: "Images/77.png"
    },
    {
        PartnerImg: "Images/78.png"
    },
    {
        PartnerImg: "Images/79.png"
    },
    {
        PartnerImg: "Images/80.png"
    },
    {
        PartnerImg: "Images/81.png"
    },
    {
        PartnerImg: "Images/82.png"
    },
    {
        PartnerImg: "Images/83.png"
    },
    {
        PartnerImg: "Images/84.png"
    },
]

export const CCArray= [
    {
        CCHeading: "Flipkart Axis Bank Credit Card",
        Annual_fee: "Rs. 500, Waived on Spends of Rs. 2 Lakh",
        Joining_fee: "Rs. 500+GST",
        CCImg:"Images/flipkartCreditCard.jpg"
    },
    {
        CCHeading: "First Millenia Card",
        Annual_fee: "Life Time Free",
        Joining_fee: "Life Time Free",
        CCImg:"Images/IDFCCreditCard.webp"
    },
    {
        CCHeading: "RBL ShopRite Card",
        Annual_fee: "Rs. 500, Waived on annual Spends of Rs. 1.5 Lakh",
        Joining_fee: "Rs. 500",
        CCImg:"Images/RBLShopriteCreditCard.webp"
    },
    {
        CCHeading: "First Select Card",
        Annual_fee: "Life Time Free",
        Joining_fee: "Life Time Free",
        CCImg:"Images/FirstSelectCard.webp"
    },
    {
        CCHeading: "YES Prosperity Reward Plus Credit Card",
        Annual_fee: "Rs. 399 PA",
        Joining_fee: "Rs. 399 PA",
        CCImg:"Images/YesRewardCreditCard.jpg"
    },
    {
        CCHeading: "Simply Click SBI Credit Card",
        Annual_fee: "Rs. 499",
        Joining_fee: "Rs. 499",
        CCImg:"Images/SBICreditCard.webp"
    },
    {
        CCHeading: "IndianOil Platinum Card",
        Annual_fee: "Rs. if spend more than 30k, free else 1000 fee",
        Joining_fee: "Nil",
        CCImg:"Images/IndianOilCreditCard.jpg"
    },
    {
        CCHeading: "Smart Card",
        Annual_fee: "Nil",
        Joining_fee: "Nil",
        CCImg:"Images/SmartCard.webp"
    },
    {
        CCHeading: "Axis Bank Noe Credit Card",
        Annual_fee: "Rs. 250 +GST",
        Joining_fee: "Rs. 250 +GST",
        CCImg:"Images/NeoCreditCard.png"
    },
]