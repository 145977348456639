import React, { useState, useEffect } from "react";
import "./home.css";
import { Images } from "../../ImgArray";
import ApplyForm from "../applyForm/ApplyForm";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import WhyUs from "../whyUs/WhyUs";

const Home = () => {
  const [partnerIndex, setPartnerIndex] = useState(12);
  const [showMore, setShowMore] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      setActiveStep(Math.floor(scrollY / window.innerHeight));
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);

  return (
    <>
      <div className="bg-[#F1F1FD] my-[50px] ">
        <section className="container flex flex-col items-center justify-center ">
          <div className="grid items-center grid-cols-1 gap-8 sectiondiv dark:text-white md:grid-cols-2 ">
          <div
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-once="true"
              className="mainimage"
            >
              <img src="/Images/m3.png" alt="Noimage" />
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="3000"
              data-aos-once="true"
              className="hero-container flex flex-col sm:text-center sm:items-center text-[#1f2360] gap-4 md:items-start md:text-start "
            >
              <h1 className="mt-10 font-bold mainheading md:text-5xl xl:text-6xl">
                Empower Your Dreams with <span className="text-transparent bg-gradient-to-r from-cyan-500 to-blue-500 bg-clip-text "> Apna Capital</span> 
              </h1>
              <p className="p-0 font-semibold">
                Fast, easy, and pocket-friendly loans tailored for your dreams.
                From home to business, we make it happen with the lowest
                interest rates.
              </p>
              <div className="space-x-4">
                <button className="rounded-md border-2 border-[#0a0033] bg-[#0a0033] px-4 py-2 text-sm text-white transition-colors duration-300 hover:bg-[#0a0033]">
                 <a href="#Discoverdiv"> Get Started</a>
                </button>
                <button className="border-1  rounded-md border-2 border-[#0a0033] hover:border-[#1f2360] px-4 py-2 text-sm transition-colors duration-300 ">
                  <Link to="/ContactUs">Talk to Us</Link>
                </button>
              </div>
            </div>
            
          </div>
        </section>
      </div>
      <div id="Discoverdiv" style={{height:"1px"}}></div>
      <a
        href="https://api.whatsapp.com/send/?phone=919111092808&text&type=phone_number&app_absent=0"
        target="_blank"
        rel="noreferrer"
      >
        <img src="Images/WPlogo.png" alt="" className="Wplogo" />
      </a>
      
      <div className="container my-20 discoverdiv" >
        <h2 className=" font-bold text-[#2a1f5b] text-2xl md:text-3xl xl:text-5xl  text-center">
          Discover Our Products
        </h2>
        <div className="applycontainer mx-[10%] mt-6 mb-20 text-[#7a7a7a]">
          Apna Capital offers a range of fast easy and pocket-friendly loans for
          all financial needs. Get the best home loans, personal loans, business
          loans, and micro-loans with the lowest interest rates. Relieve your
          stress and fulfill your aims with us.
        </div>
        <div className="gap-2 my-5 sm:grid-cols-4 xl:grid-cols-8 pb-[100px] 2xl:grid-cols-8 applyItemContainer">
          <Link
            to="/HomeLoan"
            className="w-[150px] m-auto"
            style={{ textDecoration: "none" }}
          >
            <div
              className="applyItem"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-once="true"
            >
              <img src="Images/HL.png" alt="Img" className="applyImg " />
              <br />
              <span className="applyTxt" id="WhyUs1">
                Home Loan
              </span>
            </div>
          </Link>
          <Link
            to="/CarLoan"
            className="w-[150px] m-auto"
            style={{ textDecoration: "none" }}
          >
            <div
              className="applyItem"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-once="true"
            >
              <img src="Images/CL.png" alt="Img" className="applyImg " />
              <br />
              <span className="applyTxt">Car Loan</span>
            </div>
          </Link>
          <Link
            to="/PersonalLoan"
            className="w-[150px] m-auto"
            style={{ textDecoration: "none" }}
          >
            <div
              className="applyItem"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-once="true"
            >
              <img src="Images/PL.png" alt="Img" className="applyImg " />
              <br />
              <span className="applyTxt">Personal Loan</span>
            </div>
          </Link>
          <Link
            to="/BusinessLoan"
            className="w-[150px] m-auto"
            style={{ textDecoration: "none" }}
          >
            <div
              className="applyItem"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-once="true"
            >
              <img src="Images/BL.png" alt="Img" className="applyImg " />
              <br />
              <span className="applyTxt">Business Loan</span>
            </div>
          </Link>
          {/* <Link
            to="/MortgageLoan"
            className="w-[150px] m-auto"
            style={{ textDecoration: "none" }}
          >
            <div
              className="applyItem"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-once="true"
            >
              <img src="Images/ML.png" alt="Img" className="applyImg " />
              <br />
              <span className="applyTxt">Mortgage Loan</span>
            </div>
          </Link> */}
           <Link
            to="/Education"
            className="w-[150px] m-auto"
            style={{ textDecoration: "none" }}
          >
            <div
              className="applyItem"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-once="true"
            >
              <img src="Images/education.png" alt="Img" className="applyImg " />
              <br />
              <span className="applyTxt">Education Loan</span>
            </div>
          </Link>
          {/* <Link
            to="/GoldLoan"
            className="w-[150px] m-auto"
            style={{ textDecoration: "none" }}
          >
            <div
              className="applyItem"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-once="true"
            >
              <img src="Images/GL.png" alt="Img" className="applyImg " />
              <br />
              <span className="applyTxt">Gold Loan</span>
            </div>
          </Link> */}
          <Link
            to="/Overdraft"
            className="w-[150px] m-auto"
            style={{ textDecoration: "none" }}
          >
            <div
              className="applyItem"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-once="true"
            >
              <img src="Images/loan.png" alt="Img" className="applyImg " />
              <br />
              <span className="applyTxt">Overdraft Loan</span>
            </div>
          </Link>
          <Link
            to="/VehicleLoan"
            className="w-[150px] m-auto"
            style={{ textDecoration: "none" }}
          >
            <div
              className="applyItem"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-once="true"
            >
              <img src="Images/VL.png" alt="Img" className="applyImg " />
              <br />
              <span className="applyTxt">Vehicle Loan</span>
            </div>
          </Link>
          <Link
            to="/MachineryLoan"
            className="w-[150px] m-auto"
            style={{ textDecoration: "none" }}
          >
            <div
              className="applyItem"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-once="true"
            >
              <img src="Images/MAL.png" alt="Img" className="applyImg " />
              <br />
              <span className="applyTxt">Machinery Loan</span>
            </div>
          </Link>
        </div>
      </div>
      <h1 className="mb-5 text-2xl font-bold text-center investheading md:text-3xl xl:text-5xl">
        Investment & Insurance
      </h1>
      <div className="HInI md:bg-[#1f2360]  rounded-md">
        <div className="flex flex-col items-center align-middle md:flex-row HInIDesc">
          <div className="p-3 image-container">
            <img
              src="Images/Investment&Insurance.svg"
              alt="Img"
              className=" HInImg"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-once="true"
            />
          </div>
          <div className="container insurancediv md:mx-1 text-start">
            <p
              className="md:text-white"
              data-aos="fade-left"
              data-aos-duration="3000"
              data-aos-once="true"
            >
              Insurance and investment are two essential pillars of a
              well-rounded financial plan. They offer unique benefits and play
              complementary roles in safeguarding your assets, mitigating risks,
              and growing your wealth.
            </p>
            <br />
            <p
              className="text-lg font-bold md:text-[#7a39f2]"
              data-aos="fade-left"
              data-aos-duration="4000"
              data-aos-once="true"
            >
              Insurance:
            </p>
            <p
              className="md:text-white"
              data-aos="fade-left"
              data-aos-duration="4500"
              data-aos-once="true"
            >
              Insurance acts as a protective shield against unforeseen events
              and risks that can disrupt your financial stability. Whether it's
              your health, property, vehicle, or business, insurance provides
              you with a safety net and peace of mind
            </p>
            <br />
            <p
              className="text-lg font-bold md:text-[#7a39f2]"
              data-aos="fade-left"
              data-aos-duration="5000"
              data-aos-once="true"
            >
              Investment:
            </p>
            <p
              className="md:text-white"
              data-aos="fade-left"
              data-aos-duration="5500"
              data-aos-once="true"
            >
              Investment is the art of putting your money to work with the
              expectation of generating returns and growing your wealth over
              time. It offers the opportunity to increase your financial
              resources, achieve long-term goals, and create a sustainable
              income stream.
            </p>
          </div>
        </div>
        {/* </div> */}
      </div>
      <div className="Welcome">
        <div className="WCHeading">
          <h2 className="text-center text-4xl lg:text-[32pt] lg:leading-[64pt] font-normal  text-[#2a1f5b] mb-5">
            Welcome to Apna Capital
          </h2>
        </div>
        <div className="WCContainer sm:flex sm:flex-col xl:flex-row ">
          <div
            className="WCFirstBlock "
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <img src="Images/available.png" alt="" className="WCImg" />
            <h3>Available</h3>
            <div className="WCtxt">
              Ready to communicate by phone, email, or even online chat, our
              small business loan consultants are flexible so you can continue
              to run your business while getting the answers you need.
            </div>
          </div>
          <div
            className="WCFirstBlock"
            data-aos="zoom-in"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <img src="Images/speedy.png" alt="" className="WCImg" />
            <h3>Speedy</h3>
            <div className="WCtxt">
              Let us do the work for you! Don’t have time to fill out the online
              application? Don’t worry! Give a small business loan consultant a
              call and we can fill out the application for you in just minutes.
            </div>
          </div>
          <div
            className="WCFirstBlock"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <img src="Images/knowledge.png" alt="" className="WCImg" />
            <h3>Knowledgeable</h3>
            <div className="WCtxt">
              Our small business loan consultants have the know how to answer
              any question you throw at them. As industry experts, our staff is
              ready to find the answers even your toughest business questions!
            </div>
          </div>
        </div>
        <div  id="Calculator">
        <WhyUs/>
        </div>
        <div className="PartnerBanks">
          <div className="PartnersHeading">
            <h2
              className="pt-10 text-2xl font-semibold"
              style={{ color: "#2a1f5b", fontSize: "30px" }}
            >
              OUR ASSOCIATIONS
            </h2>
          </div>
          <div className="PartnerImgContainer ">
            {Images.slice(0, partnerIndex).map((p, index) => (
              <div key={index} className="PartnerImg" data-aos="zoom-in-up">
                <img src={p.PartnerImg} alt="" />
              </div>
            ))}
          </div>
          <button
            className="ShowMore"
            onClick={() => {
              showMore ? setPartnerIndex(Images.length) : setPartnerIndex(12);
              setShowMore(!showMore);
              setShowMore(!showMore);
            }}
          >
            {showMore ? "Show More" : "Show Less"}
          </button>
        </div>
      </div>
      <ApplyForm />
    </>
  );
};

export default Home;
