import "./App.css";
import Home from "./components/home/Home";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import AboutUs from "./components/aboutUs/AboutUs";
import Insurance from "./components/insurances/insurance/Insurance";
import LifeInsurance from "./components/insurances/LifeInsurance/LifeInsurance";
import GeneralInsurance from "./components/insurances/generalInsurance/GeneralInsurance";
import Investment from "./components/investment/Investment";
import ContactUs from "./components/contactUs/ContactUs";
import Loans from "./components/Loan/homeLoan/HomeLoan";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Navigate
} from "react-router-dom";
import CreditCard from "./components/creditCard/CreditCard";
// import CCtr from './components/creditCard/CCtr';
import WhyUs from "./components/whyUs/WhyUs";
import ScrollTop from "./scrollTop/ScrollTop";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <ScrollTop/>
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route path="/AboutUs" element={<AboutUs />} />

          <Route path="/Insurance" element={<Insurance />} />

          <Route path="/LifeInsurance" element={<LifeInsurance />} />

          <Route path="/GeneralInsurance" element={<GeneralInsurance />} />

          <Route path="/Investment" element={<Investment />} />

          <Route path="/ContactUs" element={<ContactUs />} />

          <Route path="/CreditCard" element={<CreditCard />} />

          <Route path="/WhyUs" element={<WhyUs />} />

          <Route
            path="/HomeLoan"
            element={
              <Loans
                bgImg="Images/HomeLoanBG.jpg"
                SideImg="Images/HomeLoanSideImg.jpg"
                Heading="Home Loan"
                Para1="Make your dream home a reality with our leading home loan services. Apna Capital has tied knots with an array of banks providing nimble and hassle-free mortgage loans. Unlock home credit loans with a host of benefits, like low housing loan interest rates and smaller EMIs to space out your payments over a longer tenure."
                Para2="Home loan is a secured loan given to individuals who aspire to build or buy a house. The property so purchased is mortgaged in the name of the lending institution as security, till the time the loan is fully repaid."
                Para3="The lending institution will hold the original property related documents till the time the loan is fully repaid along with the interest. To buy a house, be it under construction, ready or resale property, the residence buyer can seek for loan from banks or financial institutions through financial advisors or home loan agents."
                Para4="It should be noted that the terms and conditions of the housing loan can differ widely in terms of loan amount, maturity period, interest rate, repayment mode, etc. Apna Capital has been working in this arena for several years as home loan advisors and home loan agents. We have expertise in identifying the banks and institutions that offer the loan on most friendly terms."
                // Para5="Till date, we have been instrumental and successful home loan advisors in Indore providing home loans to salaried individuals, self employed, professionals and NRIs to procure home loans at best terms."
              />
            }
          />

          <Route
            path="/MortgageLoan"
            element={
              <Loans
                bgImg="Images/MortageLoanBG.jpg"
                SideImg="Images/MortageLoanSide.jpg"
                Heading="Mortgage Loan"
                MT="MorL"
                Para1="Mortgege loan is a type of secured loan that you can avail keeping an immovable asset as mortgege with the lender. The asset can be a residential/ commercial property or other immovable properties like heavy machinery."
                Para2="Mortgage loan or loan against property means the borrower takes a loan which is secured against his current residential or commercial property. The benefit of mortgage loan is that it considers the market value of the property as on date. As such, the borrower can get higher amounts of loan. Best is, the loan money can be used to meet financials requirements of any kind."
                Para3="The word Loan Against Property means when you apply for a loan against already owned residential or commercial property & end use of the fund could be investment in business, renovation of property, extension of property, education expenses or a foreign trip etc."
              />
            }
          />

          <Route
            path="/PersonalLoan"
            element={
              <Loans
                bgImg="Images/PersonalLoanBG.jpg"
                SideImg="Images/PersonalLoanSideImg.jpg"
                Heading="Personal Loan"
                MT="PerL"
                Para1="Dream vacation, emergency expenses or alternate loan repayments, Apna Capital takes care of your financial health via instant personal loans. With a maximum limit of ₹50 lakhs, we ensure you get the lowest personal interest rate, starting at 8% p.a."
                Para2="Personal Loan is a collateral free loan based on income documents of individual or company. Since this is an unsecured loan thus the borrowers past track records of loans & credit score has to be good."
                Para3="Any financial institutional look for credit score & income because they are not your friend and it is strictly a business transaction, therefore your good income documents & credit score will help lander to land the money. Personal Loan can only be availed for any valid purpose, be it a wedding expenditure, a holiday or purchasing consumer durables, the personal loan is very handy & caters to all your needs. The amount of loan can be ranged from Rs. 50,000 – Rs. 30 Lac & the tenure for repaying the loan varies from 1 to 5 years."
              />
            }
          />

          <Route
            path="/CarLoan"
            element={
              <Loans
                bgImg="Images/CarLoanBg.jpg"
                SideImg="Images/carLoanSideImg.jpg"
                Heading="Car Loan"
                MT="CarL"
                Para1="Millions of Indians today are upwardly mobile, and on the move. Many are looking at having their own car to reach their destination on time and with comfort. Now, the years of waiting and saving are over. Our Xpress Car Loan will bring that dream of owning a vehicle within your reach in just a few minutes!"
                Para2="As a self-employed individual, if you are involved in the business of transport or you are planning to start one, Apna Capital Vehicle loan, is the ideal and flexible financial assistance for you."
                Para3="From trucks to tippers, Apna Capital offers finance for all kinds of light & heavy commercial vehicles. This loan can be availed by customers with diverse profiles. Self-employed individual can always apply for the purchase of new commercial vehicle, used commercial vehicle, refinance of commercial vehicle, repurchase of commercial vehicle and more."
              />
            }
          />

          <Route
            path="/BusinessLoan"
            element={
              <Loans
                bgImg="Images/BusinessLoanBg.png"
                SideImg="Images/BusinessLoanSideImg.webp"
                Heading="Business Loan"
                Para1="Offering premium lending solutions, Apna Capital assists diverse clients via comprehensive business loan services. Bridging the gap between lenders and loan providers, we ensure lightning-fast processing of business loans with minimal documentation and maximum efficacy."
                Para2="Loan given to self-employed, professionals or company without any collateral security is called a business loan. The sanction of business loan is based largely on the creditworthiness of the borrower and the firm. The bank or the financial institutions will first take a look at your firm’s and your credit score and analyze your credit rating before offering loan. They will also look at your financial statements for the past 3 years, bank statements, Nature of business, its current and expected returns and the market trend."
                Para3="At Apna Capital, we are the experts in helping you avail the best business loan with customer friendly terms. Businesses need money for several reasons, right from working capital needs to expansion plans to opening up a new branch or for innovation in the existing business."
              />
            }
          />

<Route
            path="/Education"
            element={
              <Loans
                bgImg="Images/GoldLoanBg.jpg"
                SideImg="Images/EducationSide.jpg"
                Heading="Education Loan"
                MT=""
                Para1="A gold loan is a method of availing finance/loan against your gold ornaments or jewellery such as bangles, necklaces, bracelets, earrings, pendants, gold coins, etc."
                Para2="As the name suggests, it is the loan given against gold. Many nationalized and private banks and other financial institutions provide this loan against idle lying gold ornaments at attractive rates. Many people nowadays are opting for this loan to meet their certain requirements and also it is better to obtain loan against your gold rather than stuffing it in their bank lockers. While opting for gold loan check the interest rates in various banks and private finances."
                Para3="Gold in India is respected in many forms; as it is religiously significant, a family heirloom, gifting option, status symbol and a nice form of investment. People decorate their brides with gold till they surrender under the weight. Tons of gold is given as offering in temples. People love to flaunt their jewelry and possessions; the more gold a lady has the wealthier she is. Quantity of your gold is proportional to your social status. With an investment point of view, in small towns and villages people are unaware of different investment options. So in the case of limited investment options, possession of gold is sort of a given. When in utmost financial crisis, gold is mortgaged or sold to improve one’s family conditions."
              />
            }
          />

<Route
            path="/Overdraft"
            element={
              <Loans
                bgImg="Images/GoldLoanBg.jpg"
                SideImg="Images/overdraftSide.jpg"
                Heading="Overdraft Loan"
                MT=""
                Para1="A gold loan is a method of availing finance/loan against your gold ornaments or jewellery such as bangles, necklaces, bracelets, earrings, pendants, gold coins, etc."
                Para2="As the name suggests, it is the loan given against gold. Many nationalized and private banks and other financial institutions provide this loan against idle lying gold ornaments at attractive rates. Many people nowadays are opting for this loan to meet their certain requirements and also it is better to obtain loan against your gold rather than stuffing it in their bank lockers. While opting for gold loan check the interest rates in various banks and private finances."
                Para3="Gold in India is respected in many forms; as it is religiously significant, a family heirloom, gifting option, status symbol and a nice form of investment. People decorate their brides with gold till they surrender under the weight. Tons of gold is given as offering in temples. People love to flaunt their jewelry and possessions; the more gold a lady has the wealthier she is. Quantity of your gold is proportional to your social status. With an investment point of view, in small towns and villages people are unaware of different investment options. So in the case of limited investment options, possession of gold is sort of a given. When in utmost financial crisis, gold is mortgaged or sold to improve one’s family conditions."
              />
            }
          />

          <Route
            path="/GoldLoan"
            element={
              <Loans
                bgImg="Images/GoldLoanBg.jpg"
                SideImg="Images/GoldLoanSideImg.webp"
                Heading="Gold Loan"
                MT="GoL"
                Para1="A gold loan is a method of availing finance/loan against your gold ornaments or jewellery such as bangles, necklaces, bracelets, earrings, pendants, gold coins, etc."
                Para2="As the name suggests, it is the loan given against gold. Many nationalized and private banks and other financial institutions provide this loan against idle lying gold ornaments at attractive rates. Many people nowadays are opting for this loan to meet their certain requirements and also it is better to obtain loan against your gold rather than stuffing it in their bank lockers. While opting for gold loan check the interest rates in various banks and private finances."
                Para3="Gold in India is respected in many forms; as it is religiously significant, a family heirloom, gifting option, status symbol and a nice form of investment. People decorate their brides with gold till they surrender under the weight. Tons of gold is given as offering in temples. People love to flaunt their jewelry and possessions; the more gold a lady has the wealthier she is. Quantity of your gold is proportional to your social status. With an investment point of view, in small towns and villages people are unaware of different investment options. So in the case of limited investment options, possession of gold is sort of a given. When in utmost financial crisis, gold is mortgaged or sold to improve one’s family conditions."
              />
            }
          />

          <Route
            path="/VehicleLoan"
            element={
              <Loans
                bgImg="Images/VehicleLoanBg.jpg"
                SideImg="Images/VehicleLoanSideImg.jpg"
                Heading="Vehicle Loan"
                MT="VehL"
                Para1="Easy and flexible finance options for vehicles, ranging from two wheelers, cars, commercial vehicles, earth movers to farm equipment."
                Para2="As a self-employed individual, if you are involved in the business of transport or you are planning to start one, Apna Capital Vehicle loan, is the ideal and flexible financial assistance for you. From trucks to tippers, Apna Capital offers finance for all kinds of light & heavy commercial vehicles. This loan can be availed by customers with diverse profiles. Self-employed individual can always apply for the purchase of new commercial vehicle, used commercial vehicle, refinance of commercial vehicle, repurchase of commercial vehicle and more."
                Para3="Apna Capital offers loans on all types of Commercial Vehicles (new and used) of all major manufacturers. To meet your aspirations, we have specialized branches dedicated for providing commercial vehicles and equipment loans. We have also opened Transport Nagars for servicing diverse profiles like truckers, milkman, shopkeeper etc.
            Our competitive interest rates are based on factors such as customer profile and location."
              />
            }
          />

          <Route
            path="/MachineryLoan"
            element={
              <Loans
                bgImg="Images/MachineryLoanBg.jpg"
                SideImg="Images/MachineryLoanSideImg.webp"
                Heading="Machinery Loan"
                MT="MacL"
                Para1="Machinery Loan is a business loan to aid entrepreneurs, small business owners, or companies to procure machinery or equipment for various business operational purposes. With this loan, you can either purchase new machinery or upgrade your existing machinery."
                Para2="SME loans are normally used by small and medium enterprises, which are keen on expanding their existing business or need to invest in a new business against the borrowed amount. At Apna Capital Services, we are committed to aid businesses with adequate loans for expansion, procurement of machinery and the like."
                Para3="A unique and innovative offering that allows you to manage your cash flows in a smarter and more effective manner."
              />
            }
          />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
