import React, { useState } from 'react';
import "./applyForm.css"
// import { height } from '@mui/system';
import axios from 'axios'; // Import Axios library

const ApplyForm = () => {
  const [service, setService] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [loan, setLoan] = useState('');
  const [message, setMessage] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const handleOptionChange = (event) => {
    setService(event.target.value);
    setLoan(''); // Reset loan value when service changes
  };

  const handleLoanChange = (event) => {
    setLoan(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the form data to the backend
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/apply`, {
        name,
        email,
        phone,
        service,
        loan,
        message,
        city,
        state
      });

      console.log(response.data); // Log the response from the backend

      // Reset the form fields after successful submission
      setService('');
      setName('');
      setEmail('');
      setPhone('');
      setLoan('');
      setMessage('');
      setCity('');
      setState('');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="applyFormContainer">
        <div className="ContactusHeading">
          <h2 className='mt-10 text-3xl text-white' style={{color:"#5e75d4"}}>Contact Us</h2>
        </div>
        <div className="applyFormContainer2">
          <form action="" className='form' onSubmit={handleSubmit}>
            <div className="formContainer">
              <input type="text" name="name" id="name" placeholder='Full Name*' required minLength={3} value={name} // Set value to the name state 
              onChange={(e) => setName(e.target.value)}/><hr className='inputHr' style={{ }} />
              <input type="email" name="email" id="email" placeholder='Email*' required minLength={5} value={email} // Set value to the email state
                onChange={(e) => setEmail(e.target.value)}/><hr className='inputHr' />
              <input type="text" name="phone" id="phone" placeholder='Contact No.*' required minLength={10} maxLength={14} value={phone} // Set value to the phone state
                onChange={(e) => setPhone(e.target.value)} /><hr className='inputHr' />
                <div className='lg:flex'>
              <select value={service} onChange={handleOptionChange} id="service">
                <option value="Select">Select</option>
                <option value="Loans">Loans</option>
                <option value="Credit Card">Credit Card</option>
                <option value="Investment">Investment</option>
                <option value="Insurance">Insurance</option>
              </select><br />
              {service === "Loans" && (
                <select name="Loan" id="Loan" className="Loan" value={loan}
                onChange={handleLoanChange}>
                  <option value="Select">Select</option>
                  <option value="Home Loan">Home Loan</option>
                  <option value="Car Loan">Car Loan</option>
                  <option value="Personal Loan">Personal Loan</option>
                  <option value="Business Loan">Business Loan</option>
                  <option value="Mortage Loan">Mortage Loan</option>
                  <option value="Gold Loan">Gold Loan</option>
                  <option value="Vehicle Loan">Vehicle Loan</option>
                  <option value="Machinery Loan">Machinery Loan</option>
                </select>
              )}
              </div>
            </div>
            <div className="formDesc">
              <input type="text" name="Your Message" id="Your Message" placeholder='Your Message'  value={message} // Set value to the message state
                onChange={(e) => setMessage(e.target.value)}/><hr className='inputHr' />
              <input type="text" name="City" id="City" placeholder='City'  value={city} // Set value to the city state
                onChange={(e) => setCity(e.target.value)}/><hr className='inputHr' />
              <input type="text" name="State" id="State" placeholder='State' value={state} // Set value to the state state
                onChange={(e) => setState(e.target.value)} /><hr className='inputHr' />
              <button className='m-10 SubmitBtn' type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ApplyForm
