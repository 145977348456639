import React from 'react'
import './insurance.css'
import ApplyForm from '../../applyForm/ApplyForm'
import {Link} from 'react-router-dom';


const Insurance = () => {
    return (
        <>
            <div className='insurance'>
                <div className="insuranceContainer">
                    <div className="insuranceImgContainer">
                        <img src="Images/Insurance.jpg" alt="Img" className="insuranceImg" />
                        <h1 className='text-4xl lg:text-[32pt] lg:leading-[64pt] font-normal'>Insurance<hr /></h1>
                    </div>
                    <div className="InsuranceContainer1">
                        <div className="InsuranceContainer2">
                            <h4 className='INSH4 font-Medium lg:text-[22pt] lg:leading-[28pt]   md:text-[14pt] md:leading-[24pt] text-[14pt] leading-[20pt]'>What is Insurance?<hr /></h4>
                            <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]' >An insurance policy can be defined as a contract between an insurance provider and a policyholder. Under this contract, the policyholder makes regular payments to the insurance company in return for a hedge against sudden financial or health risks. Depending on the terms and conditions laid down in the policy, the insurance company pays out a lump sum amount as reimbursement to the insured in the event of a claim.</p>
                            <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]' >Various factors make up an insurance policy. A proper understanding of these can help you choose a policy better suited to your needs.</p>
                        </div>
                        <div className="insuranceCardContainer">
                        <Link to="/LifeInsurance" style={{textDecoration:"none"}}> 
                            <div className="insuranceCard1">
                                <h5>Life Insurance<hr /></h5>
                                <img src="Images/LILogo.jpg" alt="Img" className="LILogoImg" />
                            </div>
        </Link>
        <Link to="/GeneralInsurance" style={{textDecoration:"none"}}> 
                            <div className="insuranceCard2">
                                <h5>General Insurance<hr /></h5>
                                <img src="Images/GILogo.jpg" alt="Img" className="GILogoImg" />
                            </div>
        </Link>
                        </div>
                    </div>
                    <div className=" HowDoesIteWork">
                        <h1 className='text-4xl lg:text-[32pt]  lg:leading-[64pt] font-normal '>How Does Insurance Work?</h1>
                        <div className="HowDoesIteWorkPara">
                            <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]' >Usually, insurance policies are in effect for a specific period of time. This is generally known as a policy term. You will be required to renew the current policy or purchase another insurance policy at the end of the policy term. Usually, insurance policies are in effect for a specific period of time. This is generally known as a policy term. You will be required to renew the current policy or purchase another insurance policy at the end of the policy term.</p>

                            <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]' >When you purchase an insurance policy, you are required to make payments to the insurance provider as premiums. Some insurance policies require monthly payments, such as health insurance. Others like home or vehicle insurance only require one or two premium payments a year.</p>

                            <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]' >Apart from premium payments, insurance policies also comprise a deductible. This is the amount you will be required to pay during a claim before the insurance company pays out its share.</p>

                            <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]' >For instance, if you have an Rs. 500 deductible included in your home insurance policy, and you file a claim of Rs. 3,000, you will be required to make a payment of Rs. 500. The insurance company will pay Rs. 2,500. You can also lower your insurance premium payments by opting for a higher deductible.</p>
                        </div>

                        {/* <div className="BenifitsContainer">
                            <h3 className="BenifitsOfInsurance">Benefits of Buying Insurance</h3><hr />
                            <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]' >Purchasing an insurance policy has numerous benefits for the individual. It provides a cover against unforeseen financial losses and helps create a fund for life after superannuation. Given below are some of the other benefits of an insurance policy:</p>
                            <ul>
                                <li><b>Cover Against Uncertainty</b>: This is one of the main advantages of having the right insurance policy. Insurance policies protect the insured against any potential loss. Buying the right insurance policy is the best way to protect yourself from the uncertainties of life.</li>
                                <li><b>Cash Flow Management</b>: Having to cover the losses out of one‘s own pocket can significantly impact cash flow management. However, the right insurance policy can help you tackle such problems. The insurance provider covers the cost of any unexpected financial losses, thereby ensuring peace of mind.</li>
                                <li><b>Investment Opportunities</b>: Unit Linked Insurance Plans invest a portion of the premium payments into various market linked investment funds. This way, you can build an investment portfolio while also getting a proper investment cover.</li>
                                <li><b>Tax Benefits</b>: Purchasing an insurance plan has various tax benefits. The premium payments you make for the insurance policy are exempt from income tax. Section 80C, 80D, and 10(10D) of the Income Tax Act detail all the tax benefits of an insurance policy.</li>
                            </ul>
                        </div> */}
                        <div className="my-5 financialRiskContainer">
                            <h3>How Does It Reduce Financial Risk?</h3><hr />
                            <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]' >Imagine while driving you accidentally hit another car, and your vehicle got damaged. If you have the right vehicle insurance, the insurance provider will cover the car repair costs minus the deductible amount that has been agreed upon in the policy contract.</p>
                            <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]' >Now, imagine there is a water leak in the bathroom, and it ruins everything in that room and the adjoining bedroom. Usually, if you have home insurance, the insurance company will pay to cover all or part of the damages, given that you have paid your deductible.</p>
                            <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]' >It is important to know that insurance companies will only cover costs for the losses that have been detailed in the insurance policy. This is why it is advised to carefully read the offer document to know what all circumstances are covered.</p>
                        </div>
                        <div className="my-2 whatIsPremium">
                            <h3>What is Insurance Premium?</h3><hr />
                            <p className='font-normal lg:text-[14pt] lg:leading-[24pt]  md:text-[14pt] md:leading-[20pt] text-[12pt] leading-[16pt]' >An insurance premium is the money that a policyholder has to pay to avail of its benefits. Such payments are paid for policies covering life insurance, health insurance, home insurance and vehicle insurance. An insurance premium can be regarded as a premium for the insurance company. Further, it can also be defined as a liability to the insurance provider, as the provider has to provide cover against the premium payments made.</p>
                        </div>
                    </div>
                </div>
                <ApplyForm/>
            </div>
        </>
    )
}

export default Insurance
